import Layout from 'cat-components/layout';
import Image from 'next/image';
import Link from 'next/link';

export default function NotFound() {
  return (
    <Layout>
      <NotFoundTemplate />
    </Layout>
  );
}

const NotFoundTemplate = () => {
  return (
    <div className="container">
      <div className="container-404 text-center">
        <div className="c4-big-img">
          <Image src="/404.png" alt="" width={200} height={200} />
        </div>
        <div className="c4-medium">{`Oops, sorry we can't find that page!`}</div>
        <div className="c4-small">
          {` Either something went wrong or the page doesn't exist anymore.`}
        </div>
        <div className="c4-button">
          <Link passHref href="/">
            <a className="btn btn-radius btn-focus">
              <i className="fa fa-chevron-circle-left mr-2" />
              Back to homepage
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};
